<template>
  <div class="content">
    <title>Securite informatique | Portailpro.gouv</title>
    <meta
      name="description"
      content="Les services du portailpro.gouv.fr ne demandent jamais de coordonnées bancaires, d'informations personnelles, de données d'identification des fournisseurs et des clients, d'informations sur les factures ou sur les références des contacts financiers par courriels ou téléphone."
    />
    <div>
      <h1 id="page-title" class="page-title fs-2500">Sécurité informatique</h1>
    </div>
    <div class="layout--content">
      <div class="layout--content-left">
        <div id="page-content">
          <h2>Renforcer la sécurité de votre compte</h2>
          <p>
            Le numéro de téléphone portable est obligatoire à l'inscription et
            permet l'authentification à double facteur.
          </p>

          <h2 class="second-header">
            Savoir identifier les techniques de fraudes
          </h2>
          <p>
            Des courriels, SMS et appels téléphoniques frauduleux usurpant
            l'identité de l'administration et de ses agents.
          </p>
          <p>
            Parmi les messages et appels frauduleux, les plus nombreux
            concernent :
          </p>
          <ul class="blocUn">
            <li>
              <span class="verticalAlignList"
                >les tentatives pour obtenir des documents concernant
                l'identification des fournisseurs et des clients, les factures
                non réglées et non échues, les références des contacts
                financiers...</span
              >
            </li>
            <li>
              <span class="verticalAlignList"
                >les tentatives de fraude à la carte bancaire qui accompagnent
                la promesse d'une restitution d'impôts ;</span
              >
            </li>
            <li>
              <span class="verticalAlignList"
                >les appels à des numéros surtaxés ;</span
              >
            </li>
          </ul>
          <p>
            D'autres pratiques abusives récentes tentent de soustraire des
            informations aux usagers (faux recensement par exemple).
          </p>
        </div>
      </div>
      <div class="layout--content-right">
        <div class="sidebar__right__texts">
          <p>
            Soyez extrêmement prudents et sachez que les services du
            <strong>
              portailpro.gouv.fr ne demandent jamais de coordonnées bancaires,
              d'informations personnelles, de données d'identification des
              fournisseurs et des clients, d'informations sur les factures ou
              sur les références des contacts financiers par courriels ou
              téléphone.</strong
            >
          </p>
          <p>
            Dans votre intérêt,<strong>
              ne répondez jamais à ces sollicitations.</strong
            >
          </p>
        </div>
      </div>
    </div>
    <div>
      <div class="fr-accordions-group mt-4">
        <section class="fr-accordion">
          <h3 class="fr-accordion__title">
            <button
              class="fr-accordion__btn"
              aria-expanded="false"
              aria-controls="accordion-114"
              @click="
                sendTag(
                  'courriel-et-sms-frauduleux-hameconnage-ou-phishing',
                  'securite-informatique',
                  'accordions'
                )
              "
            >
              Courriel et SMS frauduleux (hameçonnage ou phishing)
            </button>
          </h3>
          <div class="fr-collapse" id="accordion-114">
            <p>
              <strong
                >Vous pourriez être victime de phishing ou hameçonnage. Soyez
                vigilants !</strong
              >
            </p>

            <p>
              À réception de tout courriel officiel, examinez le nom de domaine
              qui se trouve dans l'adresse mail.<br />
              Les courriels officiels du Portailpro.gouv utilisent des adresses
              dont le domaine est @portailpro.gouv.fr.<br />
              Des émetteurs pourraient tenter de vous hameçonner en vous
              envoyant des mails frauduleux vous invitant à cliquer sur des
              liens.<br />
              Ces courriers sont des faux. Le numéro de carte bancaire ne vous
              est jamais demandé pour le paiement d’un impôt ou le remboursement
              d’un crédit d’impôt par exemple, ni pour compléter vos coordonnées
              personnelles.
            </p>

            <p>
              <strong
                >En cas de réception de courrier électronique de ce type ? Que
                faire ?</strong
              >
            </p>

            <ol>
              <li><span>1.</span> ne répondez pas à ce message ;</li>
              <li>
                <span>2.</span>
                ne cliquez pas sur les liens à l'intérieur du message (ils
                peuvent vous rediriger vers un faux site) ;
              </li>
              <li>
                <span>3.</span>supprimez le message de votre boîte aux lettres.
              </li>
            </ol>
            <p>
              D’un point de vue général, nous vous recommandons de ne jamais
              communiquer par courrier électronique ou par téléphone de données
              personnelles et surtout pas votre numéro de carte bancaire.<br />
              Le phishing (ou "hameçonnage") est une technique qui a pour
              objectif de tromper les internautes afin de leur escroquer des
              sommes d’argent. Il consiste en l’envoi de messages (courriers
              électroniques) usurpant l’identité d’administrations ou de grands
              organismes et demandant à l’internaute de fournir des informations
              personnelles, notamment un numéro de carte bancaire.<br />
              Ces courriers électroniques peuvent se présenter comme des
              messages officiels. Le message est très souvent à l’entête ou à la
              signature de l’organisme officiel. Il demande aux usagers de
              fournir leur numéro de carte bancaire, pour une démarche de
              paiement officiel. D'autres pratiques abusives récentes tentent de
              soustraire des informations aux propriétaires de locaux
              professionnels (faux recensement).
            </p>
          </div>
        </section>
        <section class="fr-accordion">
          <h3 class="fr-accordion__title">
            <button
              class="fr-accordion__btn"
              aria-expanded="false"
              aria-controls="accordion-115"
              @click="
                sendTag(
                  'faux-sites-ursupant-l-identite-portailpro-gouv-fr',
                  'securite-informatique',
                  'accordions'
                )
              "
            >
              Faux sites ursupant l’identité « portailpro.gouv.fr »
            </button>
          </h3>
          <div class="fr-collapse" id="accordion-115">
            <p>L'usurpation a lieu via deux fraudes :</p>

            <ul>
              <li>
                l'envoi d'un courriel malveillant (cf. ci-dessus) invitant le
                destinataire à se connecter en ligne à notre site ;
              </li>
              <li>
                la présentation d'un site frauduleux imitant parfaitement
                l'accès à l'espace particulier du portail pour tenter de
                subtiliser les informations personnelles des usagers pour
                pirater leur véritable compte.
              </li>
            </ul>
            <p>
              <strong>Restez vigilants ! </strong>Assurez vous que vous êtes
              toujours sur le Portailpro.gouv lorsque vous accédez à votre
              espace professionnel.
            </p>

            <p>
              Exemple : https://portailpro.gouv.fr/LoginAccess/ et non
              https://portailpro.gouvapp.fr/LoginAccess
            </p>
          </div>
        </section>
        <section class="fr-accordion">
          <h3 class="fr-accordion__title">
            <button
              class="fr-accordion__btn"
              aria-expanded="false"
              aria-controls="accordion-116"
              @click="
                sendTag(
                  'appels-telephoniques-frauduleux-hameconnage-vocal-ou-vishing',
                  'securite-informatique',
                  'accordions'
                )
              "
            >
              Appels téléphoniques frauduleux (hameçonnage vocal ou vishing)
            </button>
          </h3>
          <div class="fr-collapse" id="accordion-116">
            <p>Ces fraudes peuvent être de deux types :</p>

            <ul>
              <li>
                Appel censé émaner des services officiels pour facturer des
                appels surtaxés :<br />
                La méthode utilisée, toujours la même, signale par voie
                téléphonique à l'usager une anomalie sur son dossier, compte ou
                espace et l'invite, afin d'éviter d'éventuelles sanctions, à
                rappeler au plus vite un numéro de téléphone surtaxé. Le portail
                et les administrations officielles sont totalement étrangers à
                ces pratiques et invite les usagers à ne pas donner suite à ces
                appels.
              </li>
              <li>
                Appel frauduleux pour obtenir des données personnelles :<br />
                Certains fraudeurs se font passer pour une administration afin
                de récupérer des données personnelles ou des identifiants de
                connexion au compte fiscal. De faux agents peuvent vous
                contacter afin de collecter des données personnelles.
              </li>
            </ul>
            <p>Nous vous rappelons que :</p>

            <ul>
              <li>
                la délivrance de données personnelles et d'identifiants de
                connexion par téléphone présente un risque important ;
              </li>
              <li>
                les numéros de carte bancaire des usagers ne sont jamais
                demandés dans le but d'effectuer des transactions ou des
                remboursements sur internet ;
              </li>
              <li>
                seuls les numéros de téléphone figurant sur des documents
                officiels du Portail sont fiables.
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
    <div class="mt-5">
      <h2 class="text-center mb-4">
        Pour tout renseignement ou pour signaler une tentative d'escroquerie
      </h2>
      <div class="fr-grid-row fr-grid-row--gutters fr-mb-3w bloc-contacts">
        <div class="fr-col-12 contact">
          <div class="fr-col-12 fr-col-md-5 fr-col-lg-4 fr-mb-3w mr-2_1">
            <div class="infos-contact mr-0">
              <div class="titre">Par internet</div>
              <div class="mail-send">
                <span class="info">
                  <a
                    href="https://www.internet-signalement.gouv.fr/PortailWeb/planets/Accueil!input.action"
                    target="_blank noopener noreferrer "
                    class="fr-tag ri-mail-send-line fr-tag--icon-left"
                    title="internet-signalement.gouv.fr - Site externe"
                    @click="
                      sendTag(
                        'contact_internet_escoquerie',
                        'securite-informatique',
                        'contact'
                      )
                    "
                    >internet-signalement.gouv.fr</a
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="fr-col-12 fr-col-md-5 fr-col-lg-4 fr-mb-3w">
            <div class="infos-contact telephone">
              <div class="titre">Par téléphone</div>
              <div class="telephone">
                <div class="infos-coordonnee">
                  <span class="info">
                    <a
                      class="ri-phone-line"
                      href="tel:0805805817"
                      aria-label="Téléphoner le 0 805 805 817 "
                      @click="
                        sendTag(
                          'contact_telephone_escroquerie',
                          'securite-informatique',
                          'contact'
                        )
                      "
                    >
                      0 805 805 817
                    </a>
                  </span>
                </div>
                <div class="infos-description">
                  Numéro vert gratuit mis en place par le gouvernement
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout--content-right mobile">
      <div class="sidebar__right__texts">
        <p>
          Soyez extrêmement prudents et sachez que les services du
          <strong>
            portailpro.gouv.fr ne demandent jamais de coordonnées bancaires,
            d'informations personnelles, de données d'identification des
            fournisseurs et des clients, d'informations sur les factures ou sur
            les références des contacts financiers par courriels ou
            téléphone.</strong
          >
        </p>
        <p>
          Dans votre intérêt,<strong>
            ne répondez jamais à ces sollicitations.</strong
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SkipLinkMixin from '../mixins/skip-link-mixin';
import { sendTag } from '../utils/methodsUtils';

export default {
  mixins: [SkipLinkMixin],

  methods: {
    sendTag
  }
};
</script>

<style lang="scss" scoped>
h2 {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.75rem;
  line-height: 2.25rem;
}
a {
  box-shadow: none;
  color: $base-color !important;
}
.fr-link {
  text-decoration: underline !important;
}
a:focus {
  text-decoration: underline !important;
}
p {
  margin-bottom: 1.5rem;
}
.third-header {
  font-size: 24px;
  font-weight: bold;
  margin: 0px 0px 1rem;
  line-height: 1.25;
  letter-spacing: normal;
}

.layout--content-left {
  width: 100%;
  margin-bottom: 11px;
}
ul li::marker {
  content: '';
}
ul li:before {
  content: '●';
  font-size: 10px;
  vertical-align: baseline;
  padding-right: 0.5rem;
}
.fr-accordions-group ul li:before {
  vertical-align: middle;
}
ul.blocUn {
  padding-left: 1rem;
}
ul.blocUn li::before {
  display: table-cell;
  position: relative;
  top: -2px;
}
.verticalAlignList {
  display: table-cell;
}
.fr-accordions-group li::before {
  display: inline-block;
}
ol > li {
  padding-bottom: 0.75rem;
  padding-top: 0.5rem;
}
ol > li:nth-last-child() {
  padding-bottom: 0.25rem;
}
.fr-accordions-group ul {
  margin-left: 2rem;
}
ol > li::marker {
  content: '';
}
ol > li > span {
  font-weight: 700;
  padding: 0.5em;
}
ol {
  padding-left: 0;
  margin-bottom: 0;
}
@media only screen and (min-width: 769px) {
  .layout--content {
    display: flex;
  }
  .layout--content-left {
    width: 67%;
    margin-bottom: 11px;
    margin-right: 31px;
  }
  .layout--content-right {
    width: 33%;
    max-width: 375px;
    height: 100%;
  }
  .contact {
    justify-content: center;
    display: flex;
  }
  h2 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}
.layout--content-right {
  width: 100%;
}
.sidebar__right__texts {
  background-color: rgb(242, 242, 249);
  padding: 36px 32px;
  color: #000091;
  p {
    font-size: 14px;
  }
  p:last-child {
    margin: 0px;
  }
}
.fr-accordion__title button {
  font-weight: bold;
}

.infos-contact {
  max-width: 360px !important;
  min-height: 105px;
  height: auto;
  padding: 21px 34px 25px 35px;
  border: 1px solid rgb(221, 221, 221);
  margin: auto;
  div.telephone {
    margin-top: 6px;
  }
}
.infos-contact.telephone {
  padding: 18px 21px 17px;
  margin-left: 0;
}
.titre {
  font-size: 13px;
  border-bottom: 0;
  margin-bottom: 0;
  line-height: 1.5rem;
}
.infos-coordonnee a {
  color: rgb(13, 102, 53) !important;
  font-size: 20px;
  display: table;
  background-image: none;
  text-decoration: underline !important;
}
.infos-description {
  color: #6a6a6a;
  font-size: 12px;
  font-weight: normal;
}
.mail-send {
  margin-top: 11px;
}
.mail-send a {
  font-size: 1rem;
}
.layout--content-right.mobile {
  display: none;
}
@media (max-width: 768px) {
  .layout--content-right.mobile {
    display: block;
    margin-bottom: 1.5rem;
  }
  .layout--content-right {
    display: none;
  }
  .infos-contact {
    max-width: 100% !important;
    width: 100%;
  }
  .bloc-contacts {
    margin-bottom: 0 !important;
  }
  .fr-accordion__title button {
    padding: 0px 0.75rem;
  }
  .fr-accordion:nth-child(2) button::after {
    margin-left: 1rem;
  }
}
</style>
